<template>
    <div class="center-wrapper">
        <div class="c-header">
            <div class="c-info" v-if="userInfo">
                <img :src="userInfo.avatar" alt="">
                <p>{{userInfo.nickName}}</p>
            </div>
            <div class="c-count">
                <div class="c-ount-item">
                    <p class="item-tit">累计参与</p>
                    <p class="item-con item-join">{{list.length}}</p>
                </div>
                <div class="c-ount-item">
                    <p class="item-tit">累计获得</p>
                    <p class="item-con item-get">{{(totalAmount).toFixed(2)}}</p>
                </div>
            </div>
        </div>
        <div class="c-main">
            <div class="zj-tit">
                <p class="zj-p">中奖记录</p>
            </div>
            <div class="zj-list">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >
                    <van-cell v-for="item in list" :key="item.id">
                        <div class="cell-item">
                            <div class="cell-item-info">
                                <p class="item-info-tit">{{item.productName}}</p>
                                <p>
                                    <span v-if="item.barCode&&item.barCode.scanTime">{{item.barCode.scanTime}}</span>
                                    <span v-if="item.barCode&&item.barCode.province">{{item.barCode.province}}{{item.barCode.city}}{{item.barCode.district}}</span>
                                </p>
                            </div>
                            <div class="cell-item-count">{{(item.amount).toFixed(2)}}</div>
                        </div>
                    </van-cell>
                </van-list>
            </div>
        </div>
    </div>
</template>

<script>
// import {parseTime} from '@/utils/index';
export default {
  name: 'Center',
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      current: 1,
      totalAmount: 0,
      size: 20
    };
  },
  components: {
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  methods: {
    async onLoad(){
      try {
        const {data}  = await this.$api.getRecordList({size: this.size, current: this.current, 
        openId:this.userInfo.openId})
        // console.log(data)
        if(data.code === 200){
          let {records} = data.root;
          this.list = this.list.concat(records)
          let sum = 0;
          if(records.length > 0){
            records.forEach(el => {
              sum += el.amount
            });
            this.totalAmount = this.totalAmount+sum;
            this.current++
          } else {
            this.finished = true;
          }
          if(this.current >= data.root.pages){
            this.finished = true;
          }
        } else {
          this.finished = true;
          this.$toast(`${data.msg}`)
        }
      } catch (error) {
        console.error(error)
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.center-wrapper {
  width: 100%;
  min-height: 100vh;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  .c-header {
    margin-bottom: 13px;
    width: 100%;
    height: 204px;
    background: url("../assets/images/bg-header.png") no-repeat;
    background-size: 100% 155px;
    .c-info {
      width: 100%;
      height: 117px;
      display: flex;
      align-items: center;
      img {
        margin: 0 12px 0 32px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
      p {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #000000;
      }
    }
    .c-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 388px;
      height: 87px;
      margin: 0 auto;
      background: #ffffff;
      border-radius: 20px;
      .c-ount-item {
        box-sizing: border-box;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .item-tit {
          font-family: PingFangSC-Medium;
          font-size: 15px;
          color: #000000;
        }
        .item-con {
          position: relative;
          font-family: PingFangSC-Medium;
          font-size: 30px;
          color: #000000;
        }
        .item-join {
          padding-right: 15px;
        }
        .item-join::after {
          position: absolute;
          bottom: 7px;
          right: 0px;
          content: "次";
          font-size: 14px;
        }
        .item-get {
          color: #d0021b;
        }
        .item-get::before {
          position: absolute;
          top: 10px;
          left: -15px;
          content: "¥";
          font-size: 18px;
        }
      }
    }
  }
  .c-main {
    // height:calc(100% - 217px);
    width: 388px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .zj-tit {
      display: flex;
      align-items: center;
      width: 100%;
      height: 82px;
      font-family: PingFangSC-Regular;
      font-size: 20px;
      color: #000000;
      .zj-p {
        position: relative;
        padding-left: 35px;
      }
      .zj-p::before {
        position: absolute;
        top: -1px;
        left: 23px;
        content: "";
        width: 6px;
        height: 30px;
        background: #f9b23b;
        border-radius: 3px;
      }
    }
    .zj-list {
      padding-bottom:40px;
      width: 100%;
      .van-list {
        .van-cell {
          padding: 20px 20px;
          .cell-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cell-item-info {
              font-size: 14px;
              color: #000000;
              
              p {
                  line-height: 1;
                span {
                  display: inline-block;
                  margin: 0 4px;
                  font-family: PingFangSC-Light;
                  font-size: 11px;
                  color: #4a4a4a;
                }
                span:nth-child(1){
                  margin-left: 0;
                }
              }
              p.item-info-tit{
                line-height: 24px;
              }
            }
            .cell-item-count {
              position: relative;
              font-family: PingFangSC-Medium;
              font-size: 18px;
              color: #d0021b;
              letter-spacing: 0.53px;
            }
            .cell-item-count::before {
              position: absolute;
              top: 0;
              left: -20px;
              content: "¥";
              font-size: 18px;
              color: #d0021b;
            }
          }
        }
      }
    }
  }
}
</style>